<template>
  <div class="enter shibboleth-login">
    <form @submit.prevent="submitForm">
      <div class="flex-column shibboleth-login__wrapper">
        <p v-html="$t('genios.login.reasons.login')"></p>
        <DropdownFormElement
            :passedValue="selectedCity"
            :element="cityElement"
            @modified="updateForm($event)"
            :error="(!!cityElement.error)"
            ref="cityName"
        />
        <Error v-if="cityElement.error" :error="cityElement.error"></Error>

        <DropdownFormElement
            :passedValue="selectedMapping"
            :element="cityMappingElement"
            :disabled="!cityMappingElement.options.length"
            :error="(!!cityMappingElement.error)"
            @modified="selectedMapping = $event"
            ref="cityMapping"
        />
        <Error v-if="cityMappingElement.error" :error="cityMappingElement.error"></Error>


        <div class="send_email__form__submit_container">
          <Error v-if="formError" :error="formError"></Error>
          <Button
              :text="$t('genios.login.buttons.login')"
              variant="primary"
              name="submit"
              id="submit"
              type="submit"
              position="left"
              :toggle-text="false"
              :disabled="!selectedMapping"
          >
          </Button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Button from "../styled/Button.vue";
import Error from "../styled/Error.vue";
import ButtonLoader from "../styled/ButtonLoader.vue";
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";

export default {
  name: "ShibbolethLoginComponent",
  components: {DropdownFormElement, ButtonLoader, Error, Button},
  data() {
    return {
      isLoading: false,
      cityElement: {
        fieldValue: '',
        options: [],
        fieldLabel: 'City Name',
        error: null
      },
      cityMappingElement: {
        fieldValue: '',
        options: [],
        fieldLabel: 'City Mapping',
        error: null
      },
      selectedCity: {
        text: '',
        value: ''
      },
      selectedMapping: {
        text: '',
        value: ''
      },
      formError: null
    };
  },
  created() {
    this.getCityNames();
  },

  methods: {
    async getCityNames() {
      this.cityElement.error = null;
      this.isLoading = true;
      this.formError = null;
      try {
        const response = await fetch("/api/shibboleth/getCityNames");
        const cities = await response.json();
        this.cityElement.options = cities.map(i => ({
          text: i,
          value: i
        }));
      } catch (error) {
        this.cityElement.error = "Error fetching city names: " + error;
      } finally {
        this.isLoading = false;
      }
    },
    async fetchCityMappings() {
      this.cityMappingElement.options = [];
      this.selectedMapping = {
        text: '',
        value: ''
      };
      this.cityMappingElement.error = null;
      if (!this.selectedCity) {
        return;
      }
      this.isLoading = true;
      try {
        const response = await fetch(`/api/shibboleth/getCityNameMapping`);
        const mappings = await response.json();

        this.cityMappingElement.options = (this.selectedCity.value ? mappings[this.selectedCity.value] : []).map(i => ({
          text: i,
          value: i
        }));
      } catch (error) {
        this.cityMappingElement.error = "Error fetching city mappings" + error;
      } finally {
        this.loading = false;
      }
    },
    updateForm(event) {
      this.selectedCity = event;
      this.fetchCityMappings();
    },
    async submitForm() {
      if (!this.selectedCity?.value || !this.selectedMapping?.value) {
        this.formError = 'Please select both city and mapping';
        return;
      }
      const params = {
        city: this.selectedCity.value,
        name: this.selectedMapping.value,
        redirect: ""
      };
      const redirectUrl = await this.getShibbolethUrl(params);
      if (!redirectUrl) {
        this.formError = `Can't find Shibboleth URL for city='${params.city}' and name='${params.name}'`;
        return;
      }
      window.location.href = redirectUrl;
    },
    async getShibbolethUrl(params) {
      const queryString = new URLSearchParams(params).toString();
      const url = `/api/shibboleth/getUrl?${queryString}`;

      try {
        const response = await fetch(url);
        return await response.text(); // assuming the server returns plain URL string
      } catch (error) {
        console.error("Error fetching Shibboleth URL:", error);
        return null;
      }
    }
  }

}
</script>

<style scoped>
.shibboleth-login__wrapper {
  width: 300px;
}

@media screen and (max-width: 300px) {
  .shibboleth-login__wrapper {
    width: 80vw;
  }
}
</style>/