import {deleteSearchProfile, fetchUserSearchProfiles, updateSearchProfile} from "../../fetching";

const itemsPerPage = 50;

const initialState = {
    searchProfiles: [],
    selectedSearchProfiles: [],
    totalNumberOfPages: 0,
    currentPage: 1,
    loading: true,
    error: false,
    errorMessage: ""
}

const searchProfileModule =  {
    namespaced: true,
    state: {...initialState, ...window.sessionVariables?.['vuexState']?.searchProfileModule || {}},
    getters: {
        getSearchProfiles: (state) => (pageNumber = state.currentPage) => {
            const startIndex = (pageNumber - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            return state.searchProfiles.slice(startIndex, endIndex) || [];
        },
        getSelectedSearchProfiles: state => state.selectedSearchProfiles,
        getTotalNumberOfPages: state => state.totalNumberOfPages,
        getCurrentPage: state => state.currentPage,
        getLoading: state => state.loading,
    },
    mutations: {
        setSearchProfiles(state, searchProfiles) {
            state.searchProfiles = searchProfiles;
        },
        setSelectedSearchProfiles(state, selected) {
            state.selectedSearchProfiles =  Array.from(new Set([...selected]));
        },
        setTotalNumberOfPages(state, payload) {
            state.totalNumberOfPages = payload;
        },
        setCurrentPage(state, payload) {
            state.currentPage = payload;
        },
        setLoading(state, payload) {
            state.loading = payload;
        },
        setError(state, payload) {
            state.error = payload;
        },
        setErrorMessage(state, payload) {
            state.errorMessage = payload;
        },
        resetState(state) {
            const hasChanges = JSON.stringify(store.state) !== JSON.stringify(initialState);

            if (hasChanges) {
                Object.assign(state, initialState);
            }
        }
    },
    actions: {
        async fetchSearchProfiles({ commit }) {
            commit('setLoading', true);
            try {
                const savedValue = await fetchUserSearchProfiles();
                const items = savedValue || [];
                commit('setSearchProfiles', items);
                const totalItems = items.length;
                const totalPages = Math.ceil(totalItems / itemsPerPage);

                commit('setTotalNumberOfPages', totalPages);
            } catch (e) {
                commit('setSearchProfiles', []);
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async deleteSearchProfiles({ commit, dispatch }, ids) {
            commit('setLoading', true);
            try {
                await deleteSearchProfile(ids);
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async updateSearchProfile({ commit, dispatch, state }, searchProfile) {
            commit('setLoading', true);
            try {
                await updateSearchProfile(searchProfile);
                const storedProfiles = state.searchProfiles;
                const updatedProfiles = storedProfiles.map(item =>
                    item.profileId === searchProfile.profileId ? { ...item, ...searchProfile } : item
                );
                commit('setSearchProfiles', updatedProfiles);
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        }
    },
}
export default searchProfileModule;